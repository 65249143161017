import React, { useContext } from 'react'

import '../../message/Message.css'
import { chatSize } from './config'
import MessageContainer from './MessageContainer'
import Iframe from '../../Iframe'
import classes from './styles.module.scss'
import UserInput from '../../UserInput'
import { IframeContext } from '../../../context/IframeContext'
import { embeddedWidgetSizeSelector } from '../../../helper/embeddedWidgetSizeSelector'

const Chat = ({
  postMessage,
  messages,
  isProcessingGPTMessage,
  setIsProcessingGPTMessage,
  GPTMessagesQueue,
  resetGPTMessagesQueue,
  isHiddenWidget,
  updateMessages,
  startData,
  parentURL,
  device,
  textDisabled,
  inputTogglerValue,
  settings,
  persistentMenu,
  isFullScreenWidget,
  isMobile,
  chatId,
  getMoreChatHistory,
  showTyping,
  firstUnreadMessageId,
  screenSize,
  isEmbedded
}) => {
  const { showIframe } = useContext(IframeContext)
  const isRtl = messages && messages.length && messages[messages.length - 1].isRtl
  const { width, height } = embeddedWidgetSizeSelector()

  return (
    <div className={classes.wrap} style={chatSize(device?.height, device?.width, isFullScreenWidget,
      isEmbedded, width, height)}>
      <MessageContainer
        screenSize={screenSize}
        device={device}
        parentURL={parentURL}
        messages={messages}
        isProcessingGPTMessage={isProcessingGPTMessage}
        setIsProcessingGPTMessage={setIsProcessingGPTMessage}
        GPTMessagesQueue={GPTMessagesQueue}
        resetGPTMessagesQueue={resetGPTMessagesQueue}
        isHiddenWidget={isHiddenWidget}
        updateMessages={updateMessages}
        settings={settings}
        isMobile={isMobile}
        id="message-container"
        showTyping={showTyping}
        postMessage={postMessage}
        textDisabled={textDisabled}
        isFullScreenWidget={isFullScreenWidget}
        getMoreChatHistory={getMoreChatHistory}
        firstUnreadMessageId={firstUnreadMessageId}
        isEmbedded={isEmbedded}
      />

      <UserInput
        startData={startData}
        settings={settings}
        persistentMenu={persistentMenu}
        isFullScreenWidget={isFullScreenWidget}
        postMessage={postMessage}
        textDisabled={textDisabled}
        inputTogglerValue={inputTogglerValue}
        chatId={chatId}
        isRtl={isRtl}
        device={device}
        screenSize={screenSize}
        disableInput={isProcessingGPTMessage || GPTMessagesQueue.length}
        isEmbedded={isEmbedded}
      />

      {showIframe && (
        <Iframe
          device={device}
          color={settings.color}
          isFullScreenWidget={isFullScreenWidget}
          screenSize={screenSize}
        />
      )}
    </div>
  )
}

export default Chat
