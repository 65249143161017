import styled from 'styled-components'
import { DeviceType } from 'models/DeviceType'
import { RIBBON_BLUE, WHITE } from 'constants/colors'

const PersistentMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: relative;
`

const Hamburger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;
`

const Popup = styled.div<{ device: DeviceType; isFullScreenWidget: boolean; screenSize: DeviceType;
  isEmbedded: boolean; embeddedWidth: string}>`
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  animation: messageAnimation 0.5s;
  display: flex;
  flex-direction: column;
  width: ${({ isFullScreenWidget, device, isEmbedded, embeddedWidth }) => {
    if (isFullScreenWidget) {
      return '100vw'
    } else if (device?.width) {
      return `${device?.width}px`
    } else if (isEmbedded && embeddedWidth) {
      if (embeddedWidth.includes('px')) {
        return `${parseInt(embeddedWidth)}px`
      } else if (embeddedWidth === '100%') {
        return '100vw'
      }
    } else {
      return '360px'
    }
  }};
  max-height: ${({ isFullScreenWidget, device, screenSize }) => {
    if (isFullScreenWidget) {
      return '100vh'
    } else if (device?.height) {
      return `${device?.height}px`
    } else {
      return `${screenSize?.height - 120}px`
    }
  }};
`

const TitleWrap = styled.div<{ color: string }>`
  background: ${({ color }) => color || RIBBON_BLUE};
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const Title = styled.p`
  text-align: center;
  font-size: 14px;
  color: ${WHITE};
  width: 100%;
  margin: 0;
`

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  cursor: pointer;
  top: 16px;
  background: transparent;
  padding: 0;
  border: none;
`

const MenuItemsWrap = styled.div`
  overflow-x: hidden;
  background-color: ${WHITE};
`

export { PersistentMenu, Hamburger, Popup, TitleWrap, Title, CloseButton, MenuItemsWrap }
