import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import xss from 'xss'

import { SettingsType } from 'models/SettingsType'
import { PersistentMenuType } from 'models/PersistentMenuType'
import { DeviceType } from 'models/DeviceType'
import PersistentMenu from 'components/PersistentMenu'
import { isEmptyString } from 'actions/helpers'
import { SendIcon } from 'icons/Icons'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { INPUT_OFF } from 'constants/actionsType'
import { MAX_INPUT_LENGTH } from 'constants/index'
import { CADET_BLUE } from 'constants/colors'
import { FeedbackModalContext } from 'context/FeedbackModalContext'

import * as S from './UserInput.style'

interface Props {
  textDisabled: boolean
  inputTogglerValue: string
  settings: SettingsType
  persistentMenu: PersistentMenuType
  isFullScreenWidget: boolean
  isRtl: boolean
  chatId: number
  device: DeviceType
  showIframe: boolean
  postMessage: (m: any) => void
  startData: any
  screenSize: DeviceType
  disableInput: boolean
  isEmbedded: boolean
}

const enterKeyCode = 13

export const UserInput: React.FC<Props> = ({
  textDisabled,
  inputTogglerValue,
  settings,
  persistentMenu,
  isFullScreenWidget,
  isRtl,
  chatId,
  device,
  postMessage,
  startData,
  screenSize,
  disableInput,
  isEmbedded
}) => {
  const [text, setText] = useState('')
  const inputRef = useRef(null)
  const preventFocus = usePreventFocus()
  const { isOpenFeedbackModal } = useContext(FeedbackModalContext)
  const isHiddenTextInput = useMemo(() => inputTogglerValue === INPUT_OFF, [inputTogglerValue])

  const handleUserKeyPress = useCallback(
    event => {
      const isPrintableKey = event.key.length === 1 && !event.metaKey && !event.ctrKey

      if (document.activeElement !== inputRef.current && isPrintableKey && !isOpenFeedbackModal) {
        inputRef.current.focus()
      }
    },
    [isOpenFeedbackModal],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleChange = e => {
    if (isHiddenTextInput) return

    setText(e.target.value.slice(0, MAX_INPUT_LENGTH))
  }

  const handleEnter = e => {
    if (e.charCode === enterKeyCode && !disableInput) {
      e.preventDefault()
      handleSend()
    }
  }

  const handleSend = () => {
    if (isHiddenTextInput) return

    if (!isEmptyString(text)) {
      const message = {
        from: {
          id: chatId,
          name: startData.name || 'Widget user',
          email: startData.email || '',
        },
        type: 'text',
        isRtl: !!isRtl,
        text: xss(text),
      }
      postMessage(message)
      setText('')
    }
  }

  return (
    <S.UserInput textDisabled={textDisabled}>
      {settings?.hasPersistentMenu && persistentMenu && (
        <PersistentMenu
          device={device}
          isFullScreenWidget={isFullScreenWidget}
          settings={settings}
          persistentMenu={persistentMenu}
          screenSize={screenSize}
          postMessage={message => postMessage(message)}
          disableInput={disableInput}
          isEmbedded={isEmbedded}
        />
      )}
      <S.Input
        placeholder={settings?.inputPlaceholder}
        dir={isRtl ? 'rtl' : 'ltr'}
        id="messaging-user-input"
        autoComplete="off"
        value={text}
        onChange={handleChange}
        onKeyPress={handleEnter}
        tabIndex={preventFocus ? -1 : 0}
        data-testid={'input'}
        ref={inputRef}
        isHiddenTextInput={isHiddenTextInput}
      />
      <S.InputLengthIndicator length={text.length} data-testid={'indicator'}>
        {text.length}/{MAX_INPUT_LENGTH}
      </S.InputLengthIndicator>
      <S.Button
        onClick={handleSend}
        aria-label="send message"
        tabIndex={preventFocus ? -1 : 0}
        data-testid="button"
        isHiddenTextInput={isHiddenTextInput}
        disabled={disableInput}>
        <SendIcon width={21} height={21} color={disableInput ? CADET_BLUE : settings?.color} />
      </S.Button>
    </S.UserInput>
  )
}
